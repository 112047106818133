@font-face {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 100;
  src: url("./fonts/NotoSansKR-Light.woff2") format("woff2"),
    url("./fonts/NotoSansKR-Light.woff") format("woff"),
    url("./fonts/NotoSansKR-Light.otf") format("truetype");
}

@font-face {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/NotoSansKR-Regular.woff2") format("woff2"),
    url("./fonts/NotoSansKR-Regular.woff") format("woff"),
    url("./fonts/NotoSansKR-Regular.otf") format("truetype");
}

@font-face {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: medium;
  src: url("./fonts/NotoSansKR-Medium.woff2") format("woff2"),
    url("./fonts/NotoSansKR-Medium.woff") format("woff"),
    url("./fonts/NotoSansKR-Medium.otf") format("truetype");
}

@font-face {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: bold;
  src: url("./fonts/NotoSansKR-Black.woff2") format("woff2"),
    url("./fonts/NotoSansKR-Black.woff") format("woff"),
    url("./fonts/NotoSansKR-Black.otf") format("truetype");
}

body {
  max-height: 100vh;
  position: relative;
  margin: 0;
  font-family: "Noto Sans CJK KR", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: default;
  background-image: url("./images/web_background.png");
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 750px) {
  body {
    background-image: url("./images/mobile_background.png");
  }
}

#root {
  height: 100%;
}

code {
  font-family: "Noto Sans CJK KR", sans-serif;
}
